exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-action-js": () => import("./../../../src/pages/action.js" /* webpackChunkName: "component---src-pages-action-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landscape-js": () => import("./../../../src/pages/landscape.js" /* webpackChunkName: "component---src-pages-landscape-js" */),
  "component---src-pages-lifestyle-js": () => import("./../../../src/pages/lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-wildlife-js": () => import("./../../../src/pages/wildlife.js" /* webpackChunkName: "component---src-pages-wildlife-js" */)
}

